@import url("https://use.typekit.net/pih7csi.css");

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body { background: #fbf9f2; font-family: "montserrat", sans-serif; color: #1f2a44; }

header {
  & { position: relative; margin: 30px auto 30px; width: 95%; max-width: 1110px; display: flex; align-items: center; height: 120px; }
  .logo { position: absolute; top: 0; margin-left: -100px; }
  .assessment-title { flex: 1 1 auto; font-family: 'Butler', serif; font-weight: 700; font-size: 40px; color: #202945; text-align: center; }
}

main {
  .container { margin: 0 auto; max-width: 1110px; }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .start { 
    & { height: 580px; background: #F7D54D; display: flex; }
    .video { 
      & { position: relative; width: 48%; background: #333; height: 100%; display: flex; justify-content: center; align-items: center; object-fit: cover; overflow: hidden; }
      video { margin: 140px 0 0 150px; }
      .thumbnail { position: absolute; height: 100%; overflow: hidden; }
      .playbtn { position: absolute; top: 50%; margin-top: -48px; left: 50%; margin-left: -30px; cursor: pointer; }
    }
    .intro {
      & { width: 40%; padding: 0 6%; display: flex; align-items: left; flex-direction: column; justify-content: center; }
      h1 { margin: 0; margin-bottom: 28px; font-family: "Butler", serif; font-size: 48px; color: #1f2a44; }
      h2 { margin: 0; margin-bottom: 0; font-family: "montserrat", sans-serif; font-weight: 500; font-style: normal; color: #1f2a44; font-size: 24px; line-height: 35px; }
      .completion { 
        & { display: flex; align-items: center; margin-top: 48px; color: #1F2A44; font-family: "montserrat", sans-serif; font-weight: 500; font-size: 18px; }
        .cta { display: block; background: #1f2a44; border-radius: 3px; color: #fff; text-decoration: none; width: 240px; text-align: center; font-family: "montserrat", sans-serif; font-size: 18px; font-weight: 500; text-transform: uppercase; padding: 12px 0; margin-left: 0; }
        .cta:hover { background: #fade68; transition: 0.3s; color: #1f2a44; }
        img { width: 25px; height: 25px; margin: 0 4px 0 15px; }     
      }
    }
  }

  .assessment {
    & { min-height: 580px; background: #fff; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }

      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; }
        .bar-section { 
          .bar { height: 5px; margin: 0 5px 10px; }
          .section-title { width: 100%; text-align: center; font-family: 'montserrat', sans-serif; font-weight: 300; color: #1F2A44; font-size: 14px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: #F7D54D; }
      }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: "montserrat", sans-serif; font-weight: 400; color: #202945; font-size: 18px; }

      .question { min-height: 150px; text-align: center; font-family: "montserrat", sans-serif; font-weight: 300; color: #343333; font-size: 21px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 300; font-size: 14px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: #F7D54D; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid #F7D54D; }

        .custom-slider .MuiSlider-thumb { background: #F7D54D; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid #F7D54D; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: #F7D54D; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: #f7d54d;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: #f7d54d;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid #f7d54d;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 34px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 90px auto 30px; }
        button { color: #343333; font-weight: 600; font-size: 14px; }
      }
    }
  }

  .assessment-additional {
    & { min-height: 580px; background: #1F2A44; border-radius: 10px; color: #fff; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 { color: #fff; font-size: 18px; text-align: center; }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: "montserrat", sans-serif; font-weight: 400; color: #F7D54D; font-size: 18px; }

      .question { min-height: 150px; text-align: center; font-family: "montserrat", sans-serif; font-weight: 700; color: #fff; font-size: 24px; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: -37px; }
      .MuiInput-root { font-family: 'montserrat', sans-serif !important; }
      .form-details-text { background-color: #fff; padding: 12px 9px;  }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #fff; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 300; font-size: 14px; color: #fff; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: #F7D54D; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid #F7D54D; }

        .custom-slider .MuiSlider-thumb { background: #F7D54D; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid #F7D54D; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: #F7D54D; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: #f7d54d;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: #f7d54d;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid #f7d54d;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 34px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 80px auto 30px; }
        button { color: #fff; font-weight: 600; font-size: 14px; }
      }
    }
  }

  .details {
    & { min-height: 630px; max-width: 904px; width: 100%; background: #fbf9f2; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "Butler", serif; font-size: 36px; color: #1f2a44; padding: 32px 0 0; }
    p { text-align: center; font-size: 21px; font-weight: 500; }

    .form {
      & { display: flex; justify-content: space-between; flex-wrap: wrap; margin: 58px auto; width: 60%; }
      .form-detail { 
        & { position: relative; flex: 0 1 46%; display: block; margin-bottom: 50px; font-weight: 700; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 600; }
        .MuiInput-root { font-family: 'montserrat', sans-serif !important; }
        .MuiSelect-select .first-menu { color: #fff; }
        .form-details-text { background-color: #fff; padding: 12px 15px; max-width: 90%; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      }
    }

    .cta-btn {
      & { margin: 0 auto 30px; text-decoration: none; }
      a { text-decoration: none; }
      button { width: 236px; background: #202945; font-family: "montserrat", serif; font-size: 18px; color: #fff; border-radius: 3px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      button:hover { background: #fade68; transition: 0.3s; color: #1f2a44; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .results {
    & { min-height: 660px; background: #FBF9F2; }
    .assessment-scores-header { text-align: center; }
    .selection-form { 
      &  { width: 60%; align-items: center; margin: 20px auto; } // display: flex; }
      .form-detail { display: flex; justify-content: center; flex: 0 1 68%; } // margin-right: 4%;}
      .form-text { flex: 0 1 28%; }
      .form-details-text { background-color: #fff; border: 1px solid #efefef; padding: 12px 15px; max-width: 90%; text-align: center; }
    }

    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: 'Butler', serif; font-size: 36px; color: #333333; padding: 32px 0 0; }
    
    .progress-bar { margin: 25px auto 40px; padding-top: 80px; overflow-x: hidden; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 6px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 552px; margin: -16px auto; display: flex; clear: both; color: #333333; font-size: 14px; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 62px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 66px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 21px; color: #121212; margin: 20px 20%; }
    .result-sections { 
      & { margin: 50px 25%; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 50%; text-align: center; color: #333333; font-weight: 600; font-size: 16px; margin: 10px auto; }
        .score-txt { font-size: 14px; margin-bottom: 4px; }
        .score-slider { width: 80%; margin: 0 auto; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
    .custom-bar .MuiLinearProgress-bar { background-color: #F7D54D; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: #F7D54D; height: 5px; }

    .members-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "montserrat", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: #F7D54D; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 300; font-size: 18px; color: #4A4A4A; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }

  }

  .invite {
    & { min-height: 380px; width: 95%; max-width: 1110px; padding-bottom: 30px; background: #fbf9f2; border-radius: 3px; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: "Butler", serif; font-size: 36px; color: #1f2a44; padding: 60px 15px 0; }
    p { text-align: center; margin: 25px auto 35px; font-size: 21px; padding: 0 15px; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px auto; width: 45%; }
      .invite-number { position: absolute; left: -50px; top: 60px; font-weight: bold; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 600; }
        .MuiInput-root { font-family: 'montserrat', sans-serif !important; }
        .invite-field { background-color: #fff; padding: 8px 9px; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      }      
      .delete-btn { 
        & { position: absolute; right: -50px; top: 60px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
      .invite-another { width: 67px; background: #1F2A44; position: absolute; top: -58px; cursor: pointer; right: -86px; border-radius: 3px; text-align: center; font-size: 14px; padding: 9px 0; color: #fff; }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 290px; background: #202945; color: #fff; border-radius: 0;font-family: "montserrat", serif; border-radius: 3px; font-size: 18px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      &:hover { background: #fade68; transition: 0.3s; color: #1f2a44; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you {
    & { min-height: 380px; max-width: 926px; width: 100%; padding-bottom: 30px; background: #fbf9f2; }
    .tick { text-align: center; padding: 80px 0 0; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "Butler", serif; font-size: 36px; color: #1f2a44; padding: 32px 0 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; }
    .back-btn { 
      & { text-align: center; margin-top: 50px; }
      a { border-bottom: 2px solid #f7d54d; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }

  .invite-container {
    & {
      max-width: calc(880px - 10%);
      background: #f7d54d;
      margin: 50px auto;
      padding: 75px 5% 30px;
      font-family: "montserrat", sans-serif;
      font-size: 24px;
      line-height: 36px;
      color: #1f2a44;
      text-align: center;
    }
    .cta-btn {
      & { width: 220px; background: #1f2a44; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 3px; }
      &:hover { background: #fade68; transition: 0.3s; color: #1f2a44; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "montserrat", sans-serif; font-size: 18px; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 0 auto 40px; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #1F2A44; font-family: "montserrat", sans-serif; font-size: 18px; font-weight: 600; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 100px; border-bottom-left-radius: 100px; }
    .right { border-top-right-radius: 100px; border-bottom-right-radius: 100px; }
    .active { background: #F7D54D; color: #fff; }
  }

  .dashboard {
    & { min-height: 630px; background: #FBF9F2; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: 'Butler', serif; font-size: 36px; color: #333333; padding: 32px 0 0; }
    
    .progress-bar { margin: 80px auto 40px; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 552px; height: 26px; margin: 0 auto; border-radius: 6px; width: 100%; background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); }
    .si_ass_progress .progress-line { width: 2px; height: 55px; content: ' '; background: #4a4a4a; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #333333; bottom: 60px; left: 0; margin-left: -20px; }
    
    .pervalue h4 { position: relative; }
    .pervalue { max-width: 552px; margin: -16px auto; display: flex; clear: both; color: #333333; font-size: 14px; }
    .pervalue h4.nimp:before { content: '0%'; position: absolute; left: 0; }
    .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: 0; }

    .si_pro_header { max-width:552px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; font-size: 16px;  font-weight: 400; text-align: center; color: #333333; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 2px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: 62px; }
    .si_pro_header h4.mexpe:before { width: 2px; height: 28px; content: ' '; background: #fff; bottom: 66px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: center; font-size: 21px; color: #121212; margin: 20px 20%; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: #333333; font-weight: 600; font-size: 16px; margin: 10px auto; }
        .score-txt { font-size: 14px; margin-bottom: 4px; }
        .score-slider { width: 80%; margin: 0 auto; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
    .custom-bar .MuiLinearProgress-bar { background-color: #F7D54D; }

    table { 
      & { font-family: 'montserrat', sans-serif; border-collapse: collapse; width: 86%; margin: 70px auto 40px; font-size: 18px;font-weight: 300; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      .center-cell { text-align: center; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .header { border-style: none;border-bottom-style: solid;border-width: 3px;border-color: #F7D54D; }
      .footer { 
        & { border-style: none;border-top-style: solid;border-width: 3px;border-color: #F7D54D; color: #F7D54D; }
        .church-average { font-size: 14px; color: #373737; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "butler", serif; font-size: 36px; color: #4A4A4A; margin-top: 50px; text-align: center; }
    .most-answered {
      & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "montserrat", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
      .most-answered-detail { font-weight: 300; font-size: 18px; color: #4A4A4A; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { max-width: calc(860px - 10%); margin: 0 auto 30px; background: #1f2a44; margin: 50px auto 0; padding: 10px; border-radius: 3px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 400px; }
      .tab-buttons { 
        & {display: flex; width: 100%; margin: 0 auto; }
        button { font-family: "montserrat", sans-serif; color: #fff; font-weight: 600; text-transform: uppercase; text-align: center; padding: 15px; background: none; border: 0px; min-width: 110px; cursor: pointer; }
        .active { background: #F7D54D; color: #294469; }
      }
      .tabs-content { flex-grow: 1; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "montserrat", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
          .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 300; font-size: 14px; color: #4A4A4A; padding: 10px 15px; width: calc(100% - 30px); }
            .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #E66767; font-size: 12px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 30%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .urgency {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "montserrat", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 14px; text-align: left; }
      .urgency-slider { 
        & { position: relative; background: #F7D54D30; margin: 0 -30px; padding: 115px 9% 80px; border-radius: 3px; }
        h3 { position: absolute; top: 0; color: #858585; font-weight: 400; font-size: 18px; left: 7%; }

        .slider-labels {
          & { width: 125%; font-weight: 300; font-size: 16px; color: #858585; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 100000; margin-left: -28px; margin-top: -10px; text-align: center; font-size: 18px; font-weight: bold; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #5E5E5E; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #F7D54D; position: absolute; z-index: 9; }
        }
      }
      

      .custom-slider.MuiSlider-root { z-index: 1000; color: #F7D54D60; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 5px solid #f7d54d60; }

      .custom-slider .MuiSlider-thumb { background: #F7D54D; }
      .custom-slider .MuiSlider-thumb:before { background: #F7D54D; border: 4px solid #F7D54D; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #D8D8D8; }
      .custom-bar .MuiLinearProgress-bar { background-color: #F7D54D; }
    }

    .hurdles-enablers {
      & { max-width: calc(880px - 10%); margin: 20px auto; }
      h2 { font-family: "montserrat", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; }
      .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "montserrat", sans-serif; font-size: 14px; line-height: 1.5em; color: #4A4A4A; text-align: left; border-radius: 3px; }
      .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "montserrat", sans-serif; font-size: 14px; line-height: 1.5em; color: #4A4A4A; text-align: left; border-radius: 3px; }
      li { padding-bottom: 16px; }
    }


  }

  .testimonal-container {
    & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'montserrat', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
    .avatar { margin-right: 53px; }
    .testimonial { 
      & { position: relative; color: #fff; font-family: 'montserrat', sans-serif; font-size: 21px; line-height: 1.35em; }
      .quotation-mark { position: absolute; margin-top: -50px; }
      .designation { 
        & { color: #F7D54D; font-weight: 700; padding-top: 20px; }
        span {  font-weight: 400; }
      }
    }
  }

  .schedule-container {
    & { max-width: calc(880px - 10%); background: #fbf9f2; margin: 20px auto; padding: 75px 5% 30px; font-family: "montserrat", sans-serif; font-size: 24px; line-height: 36px; color: #1f2a44; text-align: center; border-radius: 3px; }
    .cta-btn {
      & { width: 220px; background: #1f2a44; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 3px; }
      &:hover { background: #fade68; transition: 0.3s; color: #1f2a44; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "montserrat", sans-serif; font-size: 18px; border-radius: 3px; }
    }
  }
}

.btn {
  & {
    
  }
}

footer { & { min-height: 100px; }}

// TypeForm Chat Icon Styles 
.tf-v1-popover .tf-v1-popover-button { 
  background-color: #F7D54D !important; color: #1f2a44 !important;
  // color: #F7D54D !important; background-color: #1f2a44 !important;
}

footer { min-height: 100px; }



@media only screen and (max-width: 1350px) {

  header {
    .logo { margin-left: -50px; }
  }  

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: 0; }
  }
  
  .custom-slider.MuiSlider-root { padding: 13px 0; }
  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }

}

@media only screen and (max-width: 830px) {

  header {
    & { flex-direction: column; height: auto; }
    .logo { position: relative; margin-bottom: 20px; }
  }
  
  main {
    .start {
      & { flex-direction: column; height: auto; }
      .video { width: 100%; height: 60vh; }
      .intro { width: 90%; padding: 80px 5%; height: auto; }
    }
  }

  
  main .dashboard table { font-size: 15px; }
  main .dashboard .most-answered { width: 90%; }
  main .dashboard .urgency { width: 90%; }
  main .dashboard .hurdles-enablers { width: 90%; }
  

}

@media only screen and (max-width: 650px) {

  header .assessment-title { font-size: 30px; }
  main .assessment .steps { width: 90%; padding: 0 5%; }
  main .start .intro h1 { text-align: center; }
  main .start .intro h2 { text-align: center; font-size: 18px; }
  main .start .intro h1 br { content: ""; }
  main .start .intro h1 br:after { content: " "; }

}

@media only screen and (max-width: 550px) {

  main .start .intro h1 { font-size: 36px; }
  main .start .intro .completion img { margin: 0 4px 0 0;}
  main .assessment .steps .question { min-height: 180px; font-size: 18px; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label { width: 14%; }
  main .assessment .steps .progress-indicator { margin: 50px auto 30px; }
  main .assessment .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment-additional .steps .textarea-field { width: 100%; margin-left: -10px; }
  main .assessment-additional .steps .question { font-size: 18px; }
  main .details { width: 95%; }
  main .details h1 { font-size: 32px; }
  main .details p { padding: 0 5%; font-size: 18px; }
  main .details p br { content: ""; }
  main .details p br:after { content: " "; }
  main .details .form { width: 80%; }
  main .details .form .form-detail { flex: 0 1 100%; }
  main .details .form .form-detail .form-details-text { padding-right: 0; max-width: 94%; }

  main .results { width: 95%; }
  main .results .selection-form { width: 70%; flex-wrap: wrap; flex-direction: column-reverse; }
  main .results .selection-form .form-detail { width: 100%; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; }
  main .results h1 { font-size: 32px; }
  main .results .result-text { margin: 20px 5%; font-size: 18px; }
  main .results .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; }
  main .results .progress-bar { width: 95%; }
  main .results .si_pro_header h4 { font-size: 13px; }
  main .results .si_pro_header h4.nimp:before { bottom: 55px; }
  main .results .si_pro_header h4.mexpe:before { bottom: 55px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }

  main .invite-container { width: 85%; font-size: 18px; }
  main .schedule-container { width: 85%; font-size: 18px; }
  main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }
  main .invite { width: 95%; }
  main .invite h1 { font-size: 32px; }
  main .invite p { margin: 25px 3% 35px; font-size: 18px; }
  main .invite .form { width: 65%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 95%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -20px; }
  main .invite .invite-another-person { & { }
    .invite-another { position: unset; top: 5px; margin: 0 auto; } }
  main .invite .cta-btn { margin-top: 60px; }
  main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 32px; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonal-container .testimonial { font-size: 16px; width: 90%; margin: 20px auto 0; text-align: center; }

  main .dashboard { width: 95%; }
  main .dashboard h1 { font-size: 32px; }
  main .dashboard .result-text { margin: 20px 5%; font-size: 18px; }
  main .dashboard .result-sections { margin: 50px 5%; padding-bottom: 50px; }
  main .dashboard .result-sections .score-section { flex: 0 1 100%; }
  main .dashboard .progress-bar { width: 95%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  main .dashboard .si_pro_header h4.nimp:before { bottom: 55px; }
  main .dashboard .si_pro_header h4.mexpe:before { bottom: 55px; }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard table { font-size: 13px; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 13px; }
  main .dashboard .most-answered { font-size: 16px; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  main .dashboard .alignment { width: 90%; }
  main .dashboard .alignment .tab-buttons button { font-size: 12px; min-width: 55px; padding: 10px 5px; }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 92%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }

}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 10px; }  
  main .start .intro .completion { flex-wrap: wrap; margin: 48px auto 10px; width: 100%; justify-content: center;  }
  main .start .intro .completion .cta { width: 90%; margin: auto 15% 30px; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: #f7d54d;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

